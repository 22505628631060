import ivclass from '@invideoio/web-shared/Modules/IVClass';
import { useContext, useState } from 'react';

import { PlatformType } from '@invideoio/web-shared/constants/common';
import useIntersectionObserver from '@react-hook/intersection-observer';
import useTranslation from 'next-translate/useTranslation';
import {
  DispatchContext,
  RedirectContext,
  StateContext,
} from '../../store/store';

import ErrorBoundary from '@components/ErrorBoundary';
import ToggleDurationButton from '@components/ai-pricing/ToggleDurationButton';
import PricingPlanCard from '@components/ai-pricing/plans/PricingPlanCard';
import TitleH2BlockGradient from '@components/common/Title/TitleH2BlockGradient';
import useAIPricing from '@hooks/useAIPricing';
import { SimpleHeadingType } from 'src/types';
import { AiPlan } from 'src/types/pricing.types';
import { getSeatsFromCounter } from '@components/ai-pricing/AiCredits';

interface PricingPlanProps {
  platform: PlatformType;
  theme?: 'light' | 'dark';
  pricingTitle?: SimpleHeadingType;
}

const PricingPlanAI = (props: PricingPlanProps) => {
  const [isYearlyDuration, setToggleActive] = useState(true);

  const { t } = useTranslation('common');
  const {
    platform,
    theme = 'dark',
    pricingTitle = {
      plainHeading: t(`pricing.title`),
      highlightedHeading: '',
    },
  } = props;
  const [sectionRef, setSectionRef] = useState<any>(null);
  const { isIntersecting } = useIntersectionObserver(sectionRef);

  const dispatch = useContext(DispatchContext);

  const globalState = useContext(StateContext);

  const { handlePricingCardClick } = useContext(RedirectContext);

  const AI_PRICING = `${process.env.NEXT_PUBLIC_BASE_HOST}/ai-pricing`;

  const { hasPlanFetched } = useAIPricing(isIntersecting);

  const handleOnCardClick = (plan: AiPlan) => () => {
    const { addOnSubscriptionPlans } = globalState;
    const plan2 = isYearlyDuration
      ? addOnSubscriptionPlans.generative.yearly.plan_id
      : addOnSubscriptionPlans.generative.monthly.plan_id;
    const seats2 = getSeatsFromCounter(globalState.aiCreditsCounter[plan.key]);
    handlePricingCardClick(
      plan,
      isYearlyDuration,
      globalState.seats,
      plan2,
      seats2,
    );
  };

  return (
    <section
      id="iv-ai-pricing"
      ref={setSectionRef}
      className={ivclass(
        'xl:iv-pt-150 iv-pt-100 sm:iv-pt-75',
        theme === 'dark' && 'xl:iv-pb-150 iv-pb-100 sm:iv-pb-75',
      )}
    >
      <div
        className={ivclass(
          'iv-container iv-mx-auto',
          'pricingcollapse:iv-px-25 md:iv-px-25 sm:iv-px-20',
        )}
      >
        <div
          className={ivclass(
            'iv-col-span-12',
            'iv-grid',
            'iv-text-center iv-mb-50 sm:iv-mb-40',
          )}
        >
          <TitleH2BlockGradient heading={pricingTitle} theme={theme} />
        </div>
        <div
          className={ivclass(
            'iv-text-center',
            'xl:iv-mt-75 iv-mt-50 sm:iv-mt-20',
          )}
        >
          <ToggleDurationButton
            toggleState={isYearlyDuration}
            toggleHandler={setToggleActive}
          />
        </div>
        <div className="iv-flex iv-justify-center">
          <div
            className={ivclass(
              'iv-flex iv-justify-center',
              'sm:iv-flex-col md:iv-flex-col pricingcollapse:iv-flex-col',
              'iv-gap-30 md:iv-gap-35 sm:iv-gap-30',
              'iv-mt-10',
              'xl:iv-pb-4',
            )}
          >
            <ErrorBoundary>
              {hasPlanFetched &&
                Array.isArray(globalState.aiPlanData) &&
                globalState.aiPlanData.map((plan: AiPlan) => {
                  if (plan.name === 'Free') {
                    if (theme === 'light') {
                      plan.CTA.backgroundColorClass = 'iv-bg-grey-90';
                      plan.CTA.backgroundColorClass = 'iv-bg-grey-90';
                    }
                  }

                  const options = {
                    theme,
                    isYearlyDuration,
                    planKey: plan.key,
                    planName: plan.name,
                    bannerText: plan.key === 'generative' ? 'Best Value' : '',
                    planPrice: plan.planPrice,
                    CTA: plan.CTA,
                    features: plan?.key_features,
                    onClick: handleOnCardClick(plan),
                    platform: platform,
                  };

                  return <PricingPlanCard key={plan.key} {...options} />;
                })}
            </ErrorBoundary>
          </div>
        </div>
      </div>
      <div className={ivclass('iv-text-center', 'xl:iv-mt-75 iv-mt-50')}>
        <span
          className={ivclass(
            'iv-inline-flex iv-justify-center iv-items-center',
            'iv-cursor-pointer',
            'iv-rounded-full',
            'iv-border-2',
            'iv-px-25 sm:iv-px-15',
            'iv-h-52 sm:iv-h-28',
            'iv-text-body-2xl sm:iv-text-body-m',
            'iv-font-semibold',
            'iv-transition-all iv-duration-150',
            theme === 'dark'
              ? 'iv-text-grey-0 iv-border-grey-0'
              : 'iv-text-blue iv-border-blue hover:iv-text-grey-0 hover:iv-bg-blue',
          )}
          onClick={() => {
            window.open(AI_PRICING, '_blank');
          }}
        >
          {t(`pricing.sectionCTA`)}
        </span>
      </div>
    </section>
  );
};

export default PricingPlanAI;
